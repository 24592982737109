import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/approval/certifications",
    name: "CertificationsApproval",
    component: () =>
      import("@/modules/approval/certifications/views/TheCertificationsApproval.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { layout: "AuthenticatedLayout" },
  },
  {
    path: "/approval/certification/:certificationId",
    name: "CertificationOverview",
    component: () =>
      import("@/modules/approval/certifications/views/TheCertificationApprovalOverview.vue"),
    props: true,
    beforeEnter: AuthGuard.withToken,
    meta: { title: "campaigns.meta", layout: "AuthenticatedLayout" },
  },
];
