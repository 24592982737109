import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/modules/account/views/TheLogin.vue"),
    beforeEnter: AuthGuard.withoutToken,
  },
];
