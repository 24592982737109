import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/approval/sub/certifications",
    name: "SubCertificationsApproval",
    component: () =>
      import("@/modules/approval/subcertifications/views/TheSubCertificationsApproval.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { layout: "AuthenticatedLayout" },
  },
  {
    path: "/approval/sub/certification/:certificationId",
    name: "SubCertificationOverview",
    component: () =>
      import("@/modules/approval/subcertifications/views/TheSubCertificationApprovalOverview.vue"),
    props: true,
    beforeEnter: AuthGuard.withToken,
    meta: { title: "campaigns.meta", layout: "AuthenticatedLayout" },
  },
];
